/* eslint-disable no-undef */
import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  Page,
  Tabbar,
  Tab,
  Toolbar,
  Icon,
  Dialog,
  Button,
  ActionSheet,
} from "react-onsenui";
import tabs from "./tabs";
import Navigator from "../Navigator";

class TabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      installMessageType: "",
      showNotificationPrompt: false,
      active: 1,
      tabStacks: [{ size: 0 }, { size: 0 }, { size: 0 }, { size: 0 }],
      currentIndex: 0,
    };
  }

  componentDidMount() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = !isIos && userAgent.indexOf("android") > -1;

    const isIosStandaloneMode =
      "standalone" in window.navigator && window.navigator.standalone;
    const isAndroidStandaloneMode =
      !isIos && window.matchMedia("(display-mode: standalone)").matches;

    if (isIos) {
      if (isIosStandaloneMode && window.Notification.permission === "default") {
        this.setState({ showNotificationPrompt: true });
      } else if (!isIosStandaloneMode) {
        this.setState({ installMessageType: "ios" });
      }
    } else if (isAndroid && !isAndroidStandaloneMode) {
      this.setState({ installMessageType: "android" });
    }
  }

  setActiveTab = (tabIndex) => {
    this.setState({ active: tabIndex });
  };

  renderToolbar = () => {
    const { active, tabStacks } = this.state;

    return (
      tabStacks[active].size <= 1 && (
        <Toolbar
          position="top"
          className={active === 0 ? "toolbar__dark" : "toolbar__light"}
        >
          <div className="">
            <div className={`c-navbar__content c-navbar__content--${active}`}>
              {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                <h1 className="u-ml-1 u-text-black">Quicky Staging</h1>
              ) : (
                <>
                  <img
                    alt="Quicky"
                    className="c-navbar__logo u-mr-04"
                    src={`/images/logo${active === 0 ? "_white" : ""}.png`}
                  />
                  <h1
                    className={`u-m-0 u-text-large${
                      active > 0 ? " u-text-black" : ""
                    }`}
                  >
                    BETA
                  </h1>
                </>
              )}
            </div>
          </div>
        </Toolbar>
      )
    );
  };

  handleStackChange = (stackSize) => {
    const { active, tabStacks } = this.state;
    const newTabStacks = tabStacks.map((stack, index) =>
      index === active ? { size: stackSize } : stack
    );

    this.setState({ tabStacks: newTabStacks });
  };

  handleSwipeIndexChange = (index) => {
    this.setState({ currentIndex: index });
  };

  renderTabs = () => {
    return tabs.map((tab, i) => ({
      content: (
        <Navigator
          tab={tab}
          key={`content${i}`}
          onStackChange={this.handleStackChange}
          swipeable={true}
          setActiveTab={this.setActiveTab}
        />
      ),
      tab: (
        <Tab key={`tab${i}`}>
          {tab.iconPath ? (
            <img src={tab.iconPath} alt={tab.name} />
          ) : (
            tab.icon()
          )}
        </Tab>
      ),
    }));
  };

  render() {
    const { installMessageType, showNotificationPrompt, currentIndex } = this.state;

    const tutorialSlides = [
      { title: "Title Goes Here", description: "Lorem Ipsum is simply dummy text..." },
      { title: "Another Step", description: "Explanation of the second step." },
      { title: "Final Step", description: "Instructions on how to proceed." }
    ];

    const isFirstSlide = currentIndex === 0;
    const isLastSlide = currentIndex === tutorialSlides.length - 1;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Dialog isOpen={showNotificationPrompt}>
          <div className="c-modal--notification-dialog">
            <Icon
              className="c-modal--notification-dialog__close"
              size={{ default: 26, material: 26 }}
              icon={{ default: "ion-ios-close", material: "md-close" }}
              onClick={() => this.setState({ showNotificationPrompt: false })}
            />
            <img
              src="/images/logo.png"
              className="c-modal--notification-dialog__allow-notifications"
              alt="App logo"
            />
            <p>Allow notifications from Quicky?</p>
            <div>
              <Button
                id="allow_notifications_btn"
                onClick={() => this.setState({ showNotificationPrompt: false })}
              >
                Allow
              </Button>
              <Button
                className="u-ml-1"
                onClick={() => this.setState({ showNotificationPrompt: false })}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>

        <ActionSheet
          isOpen={installMessageType !== ""}
          isCancelable={true}
          maskColor="rgba(0, 0, 0, 0.75)"
        >
          <div className="c-add-main">
            
            <SwipeableViews index={currentIndex} onChangeIndex={this.handleSwipeIndexChange} style={{ overflow: 'hidden' }}>
              {tutorialSlides.map((slide, index) => (
                <div className="c-add-to-home" key={index}>
                  <img src="/images/image-notify.png" alt="" className="c-add-image"/>
                  <p className="c-add-home-heading-txt">{slide.title}</p>
                  <span className="c-add-home-shead">{slide.description}</span>
                  <div className="c-add-to-home__indicator">
                    {tutorialSlides.map((_, i) => (
                      <span
                      key={i}
                      className={`indicator-dot ${i === currentIndex ? 'active' : ''}`}
                      onClick={() => this.handleSwipeIndexChange(i)} // Make dot clickable
                      style={{ cursor: 'pointer', margin: '0 5px' }}
                    ></span>
                    ))}
                  </div>
                  <div className="c-add-to-home__button">
                    {isFirstSlide && (
                      <Button onClick={() => this.setState({ installMessageType: "" })}>
                        Skip
                      </Button>
                    )}
                    {!isFirstSlide && !isLastSlide && (
                      <Button onClick={this.handleNext}>
                        Next
                      </Button>
                    )}
                    {isLastSlide && (
                      <Button onClick={() => this.setState({ installMessageType: "" })}>
                        Finish
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </SwipeableViews>
          </div>
        </ActionSheet>

        <Tabbar
          renderTabs={this.renderTabs}
          onPreChange={({ index }) => this.setState({ active: index })}
          swipeable
        />
      </Page>
    );
  }
}

export default TabBar;
