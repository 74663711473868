import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LOGIN, AJAX_CALL_ERROR } from "../../actionTypes";
import * as UserActions from "../../actions/user";
import * as BeanActions from "../../actions/bean";
import LoginForm from "./LoginForm";
import Register from "../register/Register";
import { SubmissionError } from "redux-form";
import { Page, Button, Card } from "react-onsenui";
import ResetPassword from "./ResetPassword";
import RenderToolbar from "../RenderToolbar";
import ServiceWorker from "../../serviceWorker";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
    };
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getBeans().then((response) => console.log(response));

    const rememberMe = localStorage.getItem("remember_me") === true;
    const email = rememberMe ? localStorage.getItem("email") : "";

    if (email) {
      this.props.initialize({ email, remember_me: rememberMe });
    }
  }

  handleSubmit = (values) => {
    const { actions } = this.props;

    return actions
      .login({
        email: values.email,
        password: values.password,
        // remember_me: !!values.remember_me,
      })
      .then((response) => {
        console.log(response);

        if (
          response.type === LOGIN &&
          response.payload.message !== "Authentication Failed"
        ) {
          const { access_token } = response.payload.data;

          localStorage.setItem("access_token", access_token);

          actions.getUser().then((response) => {
            console.log(response);
          });

          // if (values.remember_me) {
          //   localStorage.setItem("remember_me", true);
          //   localStorage.setItem("email", values.email);
          // } else {
          //   localStorage.removeItem("remember_me");
          //   localStorage.removeItem("email");
          // }

          new ServiceWorker().init();
        } else if (response.type === AJAX_CALL_ERROR) {
          throw new SubmissionError({
            _error: response.payload.response.data.message,
          });
        } else {
          this.setState({ loginFailed: true });
        }
      });
  };

  renderToolbar = () => <RenderToolbar />;

  handleSignUpClicked = () => {
    const { navigator } = this.props;
    navigator.pushPage({ component: Register });
  };

  render() {
    const { user, navigator } = this.props;
    const { loginFailed } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        {user.loggedIn ? (
          <div>
            <p>Already logged in!</p>
          </div>
        ) : (
          <>
            <Card>
              <div className="login_H_txt">
                {/* <h4 className="u-text-large u-text-black u-text-strong">LOGO</h4> */}
              </div>
              <div className="login_H_txt">
                <h4 className="u-text-large u-text-black u-text-strong">Login to your Account</h4>
              </div>

              <LoginForm onSubmit={this.handleSubmit} />

              <p className="c-login__reset">
                <label className="d-flex align-items-center">
                  <input type="checkbox" className="me-2" />
                  Remember me
                </label>
                <a
                  onClick={() =>
                    navigator.pushPage({ component: ResetPassword })
                  }
                >
                  Forgot password?
                </a>
              </p>

            {loginFailed && (
              <div className="u-text-center u-mt-1">
                <i>Your username or password is incorrect</i>
              </div>
            )}

              <div className="u-text-center u-mb-1">
                <h4>Don&apos;t have an account with us?</h4>
              </div>

              <Button
                className="button--large--cta"
                onClick={this.handleSignUpClicked}
              >
                Sign Up
              </Button>
            </Card>
          </>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions, ...BeanActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
